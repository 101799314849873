import type { MantineThemeColors } from "@mantine/core";
import map from "lodash/map";
import type { Config } from "tailwindcss";

export const mintGreen = "#15bfa3";
export const purple = "#784dd6";
export const red = "#f45a5a";
export const skyBlue = "#88d8f1";
export const yellow = "#f6ce3f";

// Each var color is the original from Figma. The rest were generated via
// Mantine's color generator: https://v7.mantine.dev/colors-generator
export const mantineColors: Partial<MantineThemeColors> = {
  "mint-green": [
    "#e7fffa", // 0
    "#b9e7df", // 1
    "#a9f4e8", // 2
    "#7cefdb", // 3
    "#59ead0", // 4
    "#44e7ca", // 5
    "#36e6c6", // 6
    "#26cbae", // 7
    mintGreen, // 8
    "#009d85", // 9
    "#0F7C6A", // 10
    "#0f6b5c", // 11
  ],
  yellow: [
    "#fffae1", // 0
    "#fef3cc", // 1
    "#fce79d", // 2
    "#f8da69", // 3
    yellow, // 4
    "#f5c722", // 5
    "#f4c30f", // 6
    "#d9ac00", // 7
    "#c19900", // 8
    "#a78300", // 9
  ],
  red: [
    "#ffe9e9", // 0
    "#ffd2d2", // 1
    "#faa1a2", // 2
    red, // 3
    "#f14343", // 4
    "#f02827", // 5
    "#f01919", // 6
    "#d60a0d", // 7
    "#bf020a", // 8
    "#a80005", // 9
    "#922317", // 10
  ],
  purple: [
    "#f4ecff", // 0
    "#e3d7fb", // 1
    "#c2aeee", // 2
    "#A183E2", // 3
    "#835bd9", // 4
    purple, // 5
    "#6737d2", // 6
    "#5729ba", // 7
    "#4c24a8", // 8
    "#421c94", // 9
  ],
  "sky-blue": [
    "#e2fbff", // 0
    "#d0f2fd", // 1
    "#a4e2f6", // 2
    skyBlue, // 3
    "#4fc4e9", // 4
    "#36bbe6", // 5
    "#23b7e5", // 6
    "#0aa0cc", // 7
    "#008fb7", // 8
    "#007da2", // 9
  ],
  gray: [
    "#F2F2F2", // 0
    "#E6E6E6", // 1
    "#D9D9D9", // 2
    "#cdcdcd", // 3
    "#b2b2b2", // 4
    "#999999", // 5
    "#8b8b8b", // 6
    "#666666", // 7
    "#5c5557", // 8
    "#333333", // 9
  ],
};

const mapMantineColorsToTailwindConfig = (
  mantineColors: Partial<MantineThemeColors>,
) =>
  map(mantineColors, (value, key) =>
    Object.fromEntries(
      (value || []).map((color, index) => [`uw-${key}-${index}`, color]),
    ),
  ).reduce((fullColorMap, individualColorObj) => ({
    ...fullColorMap,
    ...individualColorObj,
  }));

const config: Config = {
  content: ["./src/**/*.{js,ts,jsx,tsx,mdx}"],
  theme: {
    extend: {
      colors: {
        "uw-green": mintGreen,
        "uw-purple": purple,
        "uw-red": red,
        "uw-blue": skyBlue,
        "uw-yellow": yellow,
        ...mapMantineColorsToTailwindConfig(mantineColors),
      },
      fontSize: {
        xs: "0.766rem",
      },
    },
  },
  plugins: [],
};

export default config;
