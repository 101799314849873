"use client";

import Hotjar from "@hotjar/browser";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { type PropsWithChildren, useEffect, useState } from "react";

import { mantineTheme, themeVariablesResolver } from "@/app/mantine-theme";
import { ExtensionProvider } from "@/components/extension-provider";
import { SegmentContextProvider } from "@/context/segment.context";
import { UserContextProvider } from "@/context/user.context";
import { defaultShouldRetry } from "@/utils/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: defaultShouldRetry,
      refetchOnWindowFocus: false,
      // 15 minutes
      staleTime: 15 * 60 * 1000,
    },
  },
});

export const Providers = ({ children }: PropsWithChildren) => {
  const [hotjarInitialized, setHotjarInitialized] = useState(false);

  useEffect(() => {
    if (!hotjarInitialized) {
      setHotjarInitialized(true);
      Hotjar.init(parseInt(`${process.env.NEXT_PUBLIC_HOTJAR_SITE_ID}`), 6);
    }
  }, [hotjarInitialized]);

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        cssVariablesResolver={themeVariablesResolver}
        theme={mantineTheme}
      >
        {/* <Notifications /> is intentionally not wrapping the other components
            as it is not a provider, but rather a component that needs to live
            at the root level of the app */}
        <Notifications containerWidth="max-content" limit={3} />
        <UserContextProvider>
          <SegmentContextProvider>
            <ExtensionProvider>{children}</ExtensionProvider>
          </SegmentContextProvider>
        </UserContextProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
};
