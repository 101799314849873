import { isAxiosError } from "axios";

import type { ShouldRetryFunction } from "@/types/third-party";

export const defaultShouldRetry: ShouldRetryFunction = (
  failureCount,
  error,
) => {
  // These codes indicate authentication or missing data problems and are
  // unlikely to be resolved with a retry
  const dontRetryStatuses = [401, 403, 404];
  // 3 is the library default number of retry attempts
  const hasRemainingAttempts = failureCount <= 3;
  // If we're unable to determine status or status is NOT in the above array
  // fall back to default behavior
  const isRetryableStatus = !(
    isAxiosError(error) &&
    error.status &&
    dontRetryStatuses.includes(error.status)
  );

  return hasRemainingAttempts && isRetryableStatus;
};
