import { createTheme, CSSVariablesResolver, Switch } from "@mantine/core";
import { Poppins } from "next/font/google";

import {
  mantineColors,
  mintGreen,
  purple,
  red,
  skyBlue,
  yellow,
} from "../../tailwind.config";

export const mantineTheme = createTheme({
  colors: mantineColors,
  components: {
    Switch: Switch.extend({
      classNames: {
        label: "text-uw-gray-9",
        thumb: "bg-white",
      },
      defaultProps: {
        color: "mint-green.8",
      },
    }),
  },
  fontSizes: {
    xs: "0.766rem",
  },
  primaryColor: "purple",
  primaryShade: 5,
});

export const poppins = Poppins({
  subsets: ["latin"],
  weight: ["100", "400", "500", "700", "900"],
});

export const themeVariablesResolver: CSSVariablesResolver = () => ({
  dark: {},
  light: {},
  variables: {
    "--mantine-font-family": poppins.style.fontFamily,
    "--mantine-font-family-headings": poppins.style.fontFamily,
    "--uw-blue": skyBlue,
    "--uw-green": mintGreen,
    "--uw-purple": purple,
    "--uw-red": red,
    "--uw-yellow": yellow,
  },
});
